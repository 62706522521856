import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: "#FF0000",
    },
    text: {
      main: "#333333",
      secondary: "#555555",
    },
    background: {
      main: "#FFFFFF",
    },
  },
});

export default theme;
