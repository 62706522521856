import { useCallback, useEffect } from "react";
import { useMap } from "react-leaflet/hooks";
import ElevationProfile from "../ElevationProfile/ElevationProfile";

const FlyToTrackStart = ({ coordinates }) => {
  const map = useMap();

  const flyToTrack = useCallback(
    (coor) => {
      if (coor !== null) {
        map.flyTo(coor, 12);
      }
    },
    [map]
  );

  useEffect(() => {
    flyToTrack(coordinates);
  }, [coordinates, flyToTrack]);

  return <ElevationProfile />;
};

export default FlyToTrackStart;
