import { useEffect, useCallback, useRef } from "react";
import { useMap } from "react-leaflet";

import { styleStartMarker, styleEndMarker } from "./Styled";

import L from "leaflet";

const TrackStartFinish = ({ start, finish }) => {
  // console.log(JSON.stringify(start));
  // console.log(JSON.stringify(finish));

  const map = useMap();

  const oldStart = useRef(null);
  const oldFinish = useRef(null);

  const addMarker = useCallback(() => {
    if (oldStart.current !== null && oldFinish.current !== null) {
      oldStart.current.removeFrom(map);
      oldFinish.current.removeFrom(map);
    }

    if (start !== null && finish !== null) {
      const startMarker = L.marker(start, {
        icon: styleStartMarker,
      });
      const finishMarker = L.marker(finish, {
        icon: styleEndMarker,
      });

      startMarker.bindTooltip("start of the track");
      finishMarker.bindTooltip("finish of the track");

      finishMarker.addTo(map);
      startMarker.addTo(map);

      oldStart.current = startMarker;
      oldFinish.current = finishMarker;
    }

    //console.log(coorLat, coorLong, endLat, endLong);
  }, [map, start, finish]);

  useEffect(() => {
    addMarker();
  }, [addMarker]);

  return null;
};

export default TrackStartFinish;
