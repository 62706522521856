import L from "leaflet";

const styleStartMarker = L.icon({
  iconUrl:
    "data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZWxsaXBzZSBzdHlsZT0iZmlsbDogZ3JlZW47IHN0cm9rZTogcmdiKDAsIDAsIDApOyBzdHJva2Utd2lkdGg6IDEwOyIgY3g9IjUwIiBjeT0iNTAiIHJ4PSI0MCIgcnk9IjQwIj48L2VsbGlwc2U+Cjwvc3ZnPg==",
  iconSize: [14, 14],
  iconAnchor: [7, 7],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

const styleEndMarker = L.icon({
  iconUrl:
    "data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZWxsaXBzZSBzdHlsZT0iZmlsbDogcmdiKDI1NSwgMCwgMCk7IHN0cm9rZTogcmdiKDAsIDAsIDApOyBzdHJva2Utd2lkdGg6IDEwOyIgY3g9IjUwIiBjeT0iNTAiIHJ4PSI0MCIgcnk9IjQwIj48L2VsbGlwc2U+Cjwvc3ZnPg==",
  iconSize: [14, 14],
  iconAnchor: [7, 7],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

export { styleEndMarker, styleStartMarker };
