import { useCallback, useState } from "react";

const useDrawerConrols = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawer = useCallback(() => setIsDrawerOpen(true), []);

  const handleCloseDrawer = useCallback(() => setIsDrawerOpen(false), []);

  return { handleOpenDrawer, handleCloseDrawer, isDrawerOpen };
};

export default useDrawerConrols;
