import { useOutletContext } from "react-router-dom";

import { GeoJSON, LayersControl } from "react-leaflet";

import FlyToTrackStart from "../FlyToTrackStart/FlyToTrackStart";
import TrackStartFinish from "../TrackStartFinish/TrackStartFinish";
import TrackWaypoint from "../TrackWaypoint/TrackWaypoint";

import useFeature from "../../hooks/useFeature";

import { styleVisited, styleWishlist, styleDocTracks } from "./Styled";

import React from "react";

const TracksOnMap = () => {
  const { completedTracks, wishlistTracks, docTracks } = useOutletContext();

  const { onEachFeature, newTrackStart, newTrackFinish, waypoints } =
    useFeature();

  return (
    <>
      <LayersControl position="topright">
        <LayersControl.Overlay checked name="Completed Tracks">
          <GeoJSON
            data={completedTracks}
            style={styleVisited}
            onEachFeature={onEachFeature}
            interactive={true}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Wishlist Tracks">
          <GeoJSON
            data={wishlistTracks}
            style={styleWishlist}
            onEachFeature={onEachFeature}
            interactive={true}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="DOC Tracks">
          <GeoJSON data={docTracks} style={styleDocTracks} />
        </LayersControl.Overlay>
      </LayersControl>
      <FlyToTrackStart coordinates={newTrackStart} />
      <TrackStartFinish start={newTrackStart} finish={newTrackFinish} />
      <TrackWaypoint waypoints={waypoints} />
    </>
  );
};

export default TracksOnMap;
