import React, { useMemo } from "react";
import { Box, Drawer } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import { Line } from "react-chartjs-2";

import * as L from "leaflet";

import { useOutletContext } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const styles = {
  button: {
    backGroundColor: "white",
  },
  box: {
    position: "absolute",
    bottom: 0,
    zIndex: 1000,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  drawer: {
    maxHeight: "20vh",
    width: "100%",
    display: "flex",
  },
};

const ElevationProfile = () => {
  const {
    isElevationProfileOpen,
    handleCloseElevationProfile,
    trackName,
    trackCoordinates,
  } = useOutletContext();

  const altitude = useMemo(() => {
    if (trackCoordinates) {
      return (trackCoordinates || []).map((item) => item.alt);
    }
    return null;
  }, [trackCoordinates]);

  const elevationData = useMemo(() => {
    let totalGain = 0;
    if (altitude) {
      for (let i = 1; i < altitude.length; i++) {
        const prevElevation = altitude[i - 1];

        const currentElevation = altitude[i];
        const elevationGain = currentElevation - prevElevation;

        if (elevationGain > 0) {
          totalGain += elevationGain;
        }
      }
      return {
        elevationGain: totalGain,
        elevationMax: Math.max(...altitude),
        elevationMin: Math.min(...altitude),
      };
    }
    return null;
  }, [altitude]);

  const labelsDistanceInKms = useMemo(() => {
    let distance = 0;
    let labelsNew = ["0"];
    if (trackCoordinates) {
      for (let i = 1; i < trackCoordinates.length; i++) {
        const previousPoint = L.latLng(trackCoordinates[i - 1]);
        const currentPoint = L.latLng(trackCoordinates[i]);
        distance += previousPoint.distanceTo(currentPoint);
        labelsNew.push((distance / 1000).toFixed(2));
      }
    }

    return labelsNew;
  }, [trackCoordinates]);

  const data = useMemo(() => {
    return {
      labels: labelsDistanceInKms,
      datasets: [
        {
          fill: true,
          data: altitude,
          borderWidth: 1,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          pointStyle: false,
        },
      ],
    };
  }, [altitude, labelsDistanceInKms]);

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (context) => `Distance: ${context[0].label} km`, // Use dataset label as title
            //label: (context) => "Altitude: " + context.dataIndex + " m", // Custom label
            label: (context) => "Altitude: " + context.formattedValue + " m", // Show altitude with units
          },
        },
        title: {
          display: true,
          text: `${trackName} - Elev. gain: ${elevationData?.elevationGain.toFixed(
            0
          )} m`,
        },
      },

      scales: {
        x: {
          type: "linear",
          ticks: {
            display: true,
            autoSkip: true,
            stepSize: 1,
            callback: function (value, index, ticks, context) {
              return `${Number(this.getLabelForValue(value)).toFixed(0)} km`;
            },
          },
          grid: {
            display: true,
          },

          title: {
            display: false,
            text: "Distance (km)",
          },
        },
        y: {
          ticks: {
            display: true,
          },
        },
      },
    };
  }, [elevationData?.elevationGain, trackName]);

  if (!trackCoordinates) {
    return null;
  }

  return (
    <Box sx={styles.box}>
      <Drawer
        anchor="bottom"
        open={isElevationProfileOpen}
        onClose={handleCloseElevationProfile}
        sx={styles.drawer}
      >
        <Line options={options} data={data} />
      </Drawer>
    </Box>
  );
};

export default ElevationProfile;
