import React, { useMemo } from "react";

import { useUserLocation } from "../../hooks/useUserLocation";
import Box from "@mui/material/Box";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";

const userLocationStyle = {
  position: "absolute",
  top: 0,
  left: "50%",
  color: "#FFFFFF",
  backgroundColor: "rgba(51, 51, 51, 0.5)",
  transform: "translate(-50%, 0)",
  zIndex: 10000,
  display: "flex",
  alignItems: "center",
  padding: "2px 5px",
  borderRadius: "0 0 3px 3px",
  fontSize: "10px",
};

const iconStyle = { fontSize: "10px", mr: 0.5 };

const UserLocation = () => {
  const { isLoading, lat, long } = useUserLocation();

  const showUserLocation = useMemo(() => {
    return lat && long && !isLoading ? (
      <Box sx={userLocationStyle}>
        <SatelliteAltIcon fontSize="small" sx={iconStyle} /> {lat}, {long}
      </Box>
    ) : null;
  }, [isLoading, lat, long]);

  return showUserLocation;
};

export default UserLocation;
