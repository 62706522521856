import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from "react-ga4";

import LoadData from "./components/LoadData.js";
import Home from "./components/Home.js";

import "./styles/App.css";

ReactGA.initialize("G-X1FH1SG2WF");

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoadData />}>
            <Route index element={<Home />} />
            <Route path="track/:trackSlug" element={<Home />} />
            <Route path="track/:trackSlug/:details" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
