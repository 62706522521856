import { useCallback, useState } from "react";

const useElevationProfile = (trackName, trackCoordinates) => {
  const [isElevationProfileOpen, setIsElevationProfileOpen] = useState(false);

  const handleOpenElevationProfile = useCallback(
    () => setIsElevationProfileOpen(true),
    []
  );

  const handleCloseElevationProfile = useCallback(
    () => setIsElevationProfileOpen(false),
    []
  );

  return {
    isElevationProfileOpen,
    handleOpenElevationProfile,
    handleCloseElevationProfile,
    trackName,
    trackCoordinates,
  };
};

export { useElevationProfile };
export default useElevationProfile;
